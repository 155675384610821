<template>
  <div class="comparison-summary">
    <div
      v-show="pieActive"
      class="piechart-content"
    >
      <a-skeleton
        v-if="loading"
        :loading="loading"
        active
      />

      <NoResult
        v-else-if="
          (!loading && noResult) ||
          (!loading && selectedData && selectedData.length === 0)
        "
        class="piechart-no-data"
      />

      <EChart
        v-else
        :type="'pie'"
        :data="selectedData"
        :level="qs.level"
        :doughnut-chart="false"
        :chart-type="type"
        @chartClick="onChartClick"
      />
    </div>
    <div v-show="progressbarActive">
      <div
        v-if="loading"
        class="progress-bar-loading"
      >
        <a-skeleton
          :loading="loading"
          active
        />
      </div>
      <div
        v-else
        class="progress-bar-comparison"
      >
        <ProgressBarList
          :data="selectedData"
          :chart-type="type"
          :mode="mode"
          @progressClick="onChartClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, toRefs, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import numeral from 'numeral';
import EChart from '@/components/Chart/Echart.vue';
import ProgressBar from '@/components/Chart/ProgressBar.vue';
import helper from '@/services/helper';
// import chartHelper from '@/services/chartHelper.js';
import NoResult from '@/components/Error/NoResult.vue';

const ComparisonSummary = {
  name: 'ComparisonSummary',
  props: {
    mode: String,
    filter: Object,
    tabActive: String,
  },
  components: {
    EChart,
    ProgressBarList: ProgressBar,
    NoResult,
  },
  setup(props, { emit }) {
    let { mode, filter, tabActive } = toRefs(props);
    const { state, dispatch } = useStore();
    const categoryData = computed(() => state.config.categoryData);
    const campaignLevel = computed(
      () => state.account.campaignMenuData.categoryLevel
    );
    const type = ref('campaign');

    let noResult = ref(true);
    let loading = ref(false);
    let pieActive = ref(true);
    let progressbarActive = ref(false);

    let engagementData = ref([]);
    let countData = ref([]);
    let viewData = ref([]);

    const qs = reactive({
      'chart-type': 'pie',
      level: campaignLevel.value,
    });

    const selectedData = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return engagementData.value;
        } else if (mode.value.toLowerCase() === 'mention') {
          return countData.value;
        } else if (mode.value.toLowerCase() === 'view') {
          return viewData.value;
        } else {
          return engagementData.value;
        }
      } else {
        return engagementData.value;
      }
    });

    const checkSelectedData = () => {
      if (selectedData.value && selectedData.value.length > 0) {
        noResult.value = false;
      } else {
        noResult.value = true;
      }
    };

    const visibleCategories = computed(() => {
      if (
        categoryData.value &&
        categoryData.value[campaignLevel.value] &&
        categoryData.value[campaignLevel.value].categories
      ) {
        return categoryData.value[campaignLevel.value].categories
          .filter((cat) => cat.visible)
          .map((cat) => cat.category);
      } else {
        return [];
      }
    });

    const getDataSummary = async (criteria, qs) => {
      loading.value = true;
      if (criteria) {
        let result = await api.getCategoryPie(criteria, qs).catch(() => {
          console.error('History api call error', result);
        });

        if (
          result &&
          result.message != null &&
          result.message.graphData != null &&
          result.message.graphData.engagement != null &&
          result.message.graphData.count != null &&
          result.message.graphData.view != null
        ) {
          const engagementDataObjResponse = result.message.graphData.engagement;
          const countDataObjResponse = result.message.graphData.count;
          const viewDataObjResponse = result.message.graphData.view;

          // Engagement mode
          if (engagementDataObjResponse !== null) {
            let engagementResult = [];
            // check visible data
            let visibleEngagementDataTotal = 0;
            let visibleEngagementData = [];
            for (const [key, value] of Object.entries(
              engagementDataObjResponse
            )) {
              if (visibleCategories.value.includes(key)) {
                visibleEngagementDataTotal = visibleEngagementDataTotal + value;
                visibleEngagementData.push({
                  name: key,
                  value: value,
                });
              }
            }
            if (visibleEngagementData && visibleEngagementData.length) {
              for (let [index, data] of visibleEngagementData.entries()) {
                const color = getColor(
                  data.name.toLowerCase(),
                  parseInt(index)
                );
                let valueAsPercent = convertValueToPercent(
                  data.value,
                  visibleEngagementDataTotal
                );
                engagementResult.push({
                  name: data.name
                    ? data.name.charAt(0).toUpperCase() + data.name.slice(1)
                    : data.name,
                  value: data.value,
                  valueAsPercent: valueAsPercent,
                  itemStyle: {
                    color: color,
                  },
                });
              }
            }
            engagementData.value = engagementResult;
          }

          // Count (Mentioned) mode
          if (countDataObjResponse !== null) {
            let countResult = [];

            // check visible data
            let visibleCountDataTotal = 0;
            let visibleCountData = [];
            for (const [key, value] of Object.entries(countDataObjResponse)) {
              if (visibleCategories.value.includes(key)) {
                visibleCountDataTotal = visibleCountDataTotal + value;
                visibleCountData.push({
                  name: key,
                  value: value,
                });
              }
            }

            if (visibleCountData && visibleCountData.length) {
              for (let [index, data] of visibleCountData.entries()) {
                const color = getColor(
                  data.name.toLowerCase(),
                  parseInt(index)
                );
                let valueAsPercent = convertValueToPercent(
                  data.value,
                  visibleCountDataTotal
                );
                countResult.push({
                  name: data.name
                    ? data.name.charAt(0).toUpperCase() + data.name.slice(1)
                    : data.name,
                  value: data.value,
                  valueAsPercent: valueAsPercent,
                  itemStyle: {
                    color: color,
                  },
                });
              }
            }

            countData.value = countResult;
          }

          // View mode
          if (viewDataObjResponse !== null) {
            let viewResult = [];
            let visibleViewDatatotal = 0;
            let visibleViewData = [];
            for (const [key, value] of Object.entries(viewDataObjResponse)) {
              if (visibleCategories.value.includes(key)) {
                visibleViewDatatotal = visibleViewDatatotal + value;
                visibleViewData.push({
                  name: key,
                  value: value,
                });
              }
            }

            if (visibleViewData && visibleViewData.length) {
              for (let [index, data] of visibleViewData.entries()) {
                const color = getColor(
                  data.name.toLowerCase(),
                  parseInt(index)
                );
                let valueAsPercent = convertValueToPercent(
                  data.value,
                  visibleViewDatatotal
                );
                viewResult.push({
                  name: data.name
                    ? data.name.charAt(0).toUpperCase() + data.name.slice(1)
                    : data.name,
                  value: data.value,
                  valueAsPercent: valueAsPercent,
                  itemStyle: {
                    color: color,
                  },
                });
              }
            }

            viewData.value = viewResult;
          }
          checkSelectedData();
        }
      }
      checkActiveChange();
      loading.value = false;
    };

    const getColor = (catKey, index) => {
      let color;
      let result;
      if (
        categoryData.value &&
        categoryData.value[qs.level] &&
        categoryData.value[qs.level].categories
      ) {
        const categories = categoryData.value[qs.level].categories;
        result = categories.find(
          (c) => c.visible && c.category === catKey.toLowerCase()
        );
      }
      color = result && result.color ? result.color : null;

      if (!color) {
        color = helper.getColor(catKey, index);
      }
      return color;
    };

    const convertValueToPercent = (value, total) => {
      return numeral((value / total) * 100).format('0.00');
    };
    const checkActiveChange = () => {
      if (tabActive.value === 'pie') {
        pieActive.value = true;
        progressbarActive.value = false;
      } else {
        progressbarActive.value = true;
        pieActive.value = false;
      }
    };

    // Modal
    const onChartClick = (params) => {
      const newFilter = JSON.parse(JSON.stringify(filter.value));
      let f = {
        payload: {
          title: `${params.name} campaign`,
        },
        criteria: {
          ...newFilter,
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };
      const source = params.name.toLowerCase();
      f.criteria['subCategory'] = [
        { category: [source], level: campaignLevel.value },
      ];
      dispatch('message/showMessageModal', f);
    };

    // INIT part
    const init = (filterValue) => {
      getDataSummary(filterValue, qs);
    };

    watch(
      () => filter.value,
      () => {
        const f = filter.value;
        init(f);
      }
    );

    watch(
      () => tabActive.value,
      () => {
        checkActiveChange();
      }
    );

    watch(
      () => campaignLevel.value,
      () => {
        // change level on category pie chart
        qs.level = campaignLevel.value;
        const filterValue = filter.value;
        init(filterValue);
      }
    );

    onMounted(() => {
      const filterValue = filter.value;
      init(filterValue);
    });

    watch(noResult, (n) => {
      emit('noResultChange', n);
    });

    emit('noResultChange', noResult.value);

    return {
      tabActive,
      pieActive,
      progressbarActive,
      loading,
      selectedData,
      onChartClick,
      noResult,
      qs,
      type,
    };
  },
};

export default ComparisonSummary;
</script>
<style lang="scss" scoped>
@import '../../../config/theme/colors.json';
.piechart-content {
  height: 525px;
  display: flex;
  align-items: center;

  .piechart-no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.progress-bar-loading {
  max-height: 655px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.progress-bar-comparison {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 525px;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 6px;
  }

  @media screen and (max-width: 600px) {
    min-height: 181px;
  }
}

.progress-bar-content-little {
  min-height: calc(100% + 50px);
}
</style>
