<template>
  <div class="campaign-page">
    <CustomPageHeader
      title="Campaign Comparison"
      :hasSubtitle="false"
      :hasExport="hasExport"
      :disabledExport="isGenerating"
      @toggleExport="toggleExport"
    ></CustomPageHeader>
    <div
      class="page-content"
      @scroll="onScroll"
    >
      <div class="filter-div">
        <div
          v-if="!isMobileScreen"
          class="social-movement-filter-mode"
        >
          <span class="social-movement-data-aspect">Measurement: </span>
          <a-radio-group
            v-model:value="selectedDataAspect"
            button-style="solid"
            size="default"
          >
            <a-radio-button
              v-for="mode in modeOptions"
              :key="mode.value"
              :value="mode.value"
              style="height: 34px"
            >
              {{ mode.label }}
            </a-radio-button>
          </a-radio-group>
        </div>
        <div
          v-else
          class="mobile-social-movement-filter-mode"
        >
          <div class="section-title">Social Movement</div>
          <a-dropdown
            placement="bottomRight"
            :getPopupContainer="(trigger) => trigger.parentNode"
          >
            <img :src="require('@/assets/images/icon/icon-sliders.svg')" />
            <template #overlay>
              <a-menu
                id="mode-menu-mobile"
                @click="handleModeClick"
              >
                <a-menu-item-group title="Measurement:">
                  <a-menu-item
                    v-for="mode in modeOptions"
                    :key="mode.value"
                    :style="
                      selectedDataAspect === mode.value
                        ? selectedModeStyle
                        : unSelectedModeStyle
                    "
                  >
                    {{ mode.label }}
                  </a-menu-item>
                </a-menu-item-group>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>

      <ComparisonOverview
        :mode="mode"
        :filterResult="filterResult"
      ></ComparisonOverview>
      <ComparisonMetricAndSummary
        :mode="selectedDataAspect"
        :filterResult="filterResult"
      ></ComparisonMetricAndSummary>
      <a-row :gutter="25">
        <a-col
          :xl="24"
          :lg="24"
          :xs="24"
        >
          <ComparisonPerformance
            :mode="mode"
            :filterResult="filterResult"
          ></ComparisonPerformance>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  onBeforeUnmount,
  onUnmounted,
  watch,
  onMounted,
} from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import CustomPageHeader from '@/components/Layout/CustomPageHeader.vue';
import ComparisonOverview from './ComparisonOverview.vue';
import ComparisonPerformance from './ComparisonPerformance.vue';
import ComparisonMetricAndSummary from './ComparisonMetricAndSummary.vue';
export default {
  name: 'CampaignComparison',
  components: {
    CustomPageHeader,
    ComparisonOverview,
    ComparisonPerformance,
    ComparisonMetricAndSummary,
  },
  setup() {
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const userInfo = computed(() => store.getters['account/userInfo']);
    const filterCriteria = computed(
      () => store.getters['filter/filterCriteria'],
    );
    const sourceNameList = computed(
      () => store.getters['account/sourceNameList'],
    );
    const campaignLevel = computed(
      () => store.state.account.campaignMenuData.categoryLevel,
    );
    const campaignMenuData = computed(
      () => store.state.account.campaignMenuData.categoryList,
    );

    let mode = ref('engagement'); //engagement,mention,view
    let selectedDataAspect = ref('engagement');
    let filterResult = ref();
    const modeOptions = [
      { label: 'Engagement', value: 'engagement' },
      { label: 'Mentioned', value: 'mention' },
      { label: 'View', value: 'view' },
    ];
    let activeInterval = ref();
    const handleModeClick = async (e) => {
      selectedDataAspect.value = e.key;
    };

    // Export
    let isOpenExportCard = ref(false);
    const toggleExport = (fileType) => {
      exportFile(fileType);
      isOpenExportCard.value = true;
    };

    const closeExportCard = () => {
      isOpenExportCard.value = false;
    };

    let generating = ref(false);

    const exportFile = async (fileType) => {
      generating.value = true;

      if (
        filterCriteria.value &&
        filterCriteria.value.source &&
        filterCriteria.value.source.length <= 0
      ) {
        filterCriteria.value.source = [...sourceNameList.value];
      }
      if (fileType.version === 'report3.2') {
        await api
          .newExport32(filterResult.value, fileType.type, null)
          .catch(() => {});
      } else {
        await api
          .newExport(filterResult.value, fileType.type, null)
          .catch(() => {});
      }

      getExportStatus();
      activeInterval.value = setInterval(() => {
        getExportStatus();
      }, 5000);
    };

    // export card mini
    const userName = computed(() => store.getters['account/userName']);
    let exportHistory = ref([]);

    const getExportStatus = async () => {
      const result = await api
        .getExportStatus(userName.value, 1)
        .catch(() => {});
      if (result && result.message) {
        const { records } = result.message;
        exportHistory.value = records;

        const loading = exportHistory.value.some(
          (ele) =>
            ele.exported_status === 'new' ||
            ele.exported_status === 'generating',
        );

        if (!loading) {
          generating.value = false;
          if (activeInterval.value) {
            clearInterval(activeInterval.value);
          }
        } else {
          generating.value = true;
        }
      }
    };

    const exportData = computed(() => {
      // if (exportHistory.value && exportHistory.value.length > 0) {
      //   return exportHistory.value;
      // }
      // return [];
      return exportHistory.value;
    });

    // if file is downloading > disable button
    const isGenerating = computed(() => {
      return generating.value;
    });

    const hasExport = computed(() => {
      if (userInfo.value && userInfo.value.id === '61e53d2e08f1d127489a051d') {
        return false;
      } else if (isMobileScreen.value === true) {
        return false;
      }
      return true;
    });

    const onScroll = () => {
      const scrollElement = document.getElementsByClassName('ant-popover');

      if (scrollElement) {
        for (let ele of scrollElement) {
          ele.style.display = 'none';
        }
      }
    };

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };

    const init = async (fv) => {
      await store.dispatch('account/getCampaignMenu');
      if (campaignMenuData.value) {
        if (campaignLevel.value === 0) {
          const categoryList = [];
          for (let cat of fv.category) {
            for (let list of campaignMenuData.value) {
              if (list.categoryName === cat) {
                categoryList.push(cat);
              }
            }
          }
          filterResult.value = {
            ...fv,
            category: categoryList,
            subCategory: [],
          };
        } else {
          const campaignCat = [];
          for (let sub of fv.subCategory) {
            if (parseInt(sub.level) === campaignLevel.value) {
              const subCat = sub.category;
              let obj = {
                level: sub.level,
                category: [],
              };
              for (let cat of subCat) {
                for (let list of campaignMenuData.value) {
                  if (list.categoryName === cat) {
                    obj.category.push(cat);
                  }
                }
              }
              campaignCat.push(obj);
            } else {
              campaignCat.push(sub);
            }
          }
          filterResult.value = {
            ...fv,
            subCategory: campaignCat,
          };
        }
      }
    };

    onBeforeUnmount(() => {
      if (activeInterval.value) {
        clearInterval(activeInterval.value);
      }
      store.dispatch('seemore/close');
    });

    onUnmounted(() => {
      clearInterval(activeInterval.value);
    });

    watch(
      () => selectedDataAspect.value,
      (previous) => {
        mode.value = previous;
      },
    );

    watch(
      () => filterCriteria.value,
      () => {
        init(filterCriteria.value);
      },
    );

    onMounted(() => {
      init(filterCriteria.value);
    });

    return {
      mode,
      modeOptions,
      isMobileScreen,
      selectedDataAspect,
      handleModeClick,
      toggleExport,
      isOpenExportCard,
      closeExportCard,
      isGenerating,
      getExportStatus,
      exportData,
      hasExport,
      onScroll,
      selectedModeStyle,
      unSelectedModeStyle,
      filterResult,
      filterCriteria,
      campaignMenuData,
      campaignLevel,
    };
  },
};
</script>

<style lang="scss" scoped>
.campaign-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .section-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }
  .filter-div {
    padding-bottom: 15px;
  }
  .page-content {
    padding: 28px 30px 0;
    overflow: auto;
    width: 100%;
    .comparison {
      padding: 24px 0;
    }
    .social-movement-filter-mode {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .social-movement-data-aspect {
        margin-right: 14px;
        font-size: 14px;
        color: #5a5f7d;
      }
      .ant-radio-group {
        .ant-radio-button-wrapper:first-child {
          border-radius: 4px 0 0 4px;
        }
        .ant-radio-button-wrapper:last-child {
          border-radius: 0 4px 4px 0;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .social-movement-filter-mode {
        justify-content: flex-end;
        text-align: center;
      }
    }
    @media screen and (max-width: 575px) {
      .mobile-social-movement-filter-mode {
        margin-right: 10px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
