<template>
  <a-card
    class="campaign-comparison no-y-padding"
    title="Campaign Overview"
  >
    <div class="card">
      <a-row
        class="overview-row"
        :gutter="[25, 25]"
      >
        <a-col class="pd-unset comparison-col">
          <ComparisonOverview
            :mode="mode"
            :filter="filterResult"
          />
        </a-col>
      </a-row>
      <a-row class="comparison-row">
        <a-col
          :span="24"
          :offset="0"
        >
          <ComparisonTrending
            :mode="mode"
            :filterResult="filterResult"
          />
        </a-col>
      </a-row>
    </div>
  </a-card>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import ComparisonOverview from '@/components/Chart/ComparisonOverview.vue';
import ComparisonTrending from './ComparisonTrending.vue';

export default {
  name: 'CampaignOverview',
  components: {
    ComparisonTrending,
    ComparisonOverview,
  },
  props: {
    filterResult: Object,
    mode: String,
  },
  setup() {
    const { state } = useStore();
    const isMobileScreen = computed(() => state.isMobileScreen);
    let loading = ref(false);
    let seriesData = ref([]);
    const chartType = ref('line');

    return {
      seriesData,
      chartType,
      loading,
      isMobileScreen,
    };
  },
};
</script>

<style lang="scss" scoped>
.campaign-comparison {
  text-transform: capitalize;
  @media only screen and (max-width: 600px) {
    .overview-row {
      margin: 0 auto !important;
      padding: 0 6px;
    }
    .comparison-row {
      margin: 0 auto !important;
      padding: 0;
    }
  }
  .comparison-col {
    width: 100%;
  }
}
.card {
  min-height: 490px;
}

.data-representation-selector-wrapper {
  display: flex;
  justify-content: flex-end;
}

.chart {
  margin-top: 25px;
}
.chart-type {
  width: fit-content;
  margin-left: auto;
  padding: 0;
  line-height: 1.4;
}

.accumulation-wrapper {
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  row-gap: 16px;
  column-gap: 16px;
  .acc-toggle {
    margin-top: 0;
    justify-content: center;
  }
  @media only screen and (max-width: 1330px) and (min-width: 992px) {
    .acc-toggle {
      order: 2;
      margin-right: 48px;
    }
    .mode-toggle {
      order: 1;
    }
  }
}

// @media only screen and (max-width: 992px) {
//   .data-representation-selector-wrapper {
//     justify-content: flex-start;
//   }
//   .acc-toggle {
//     order: 1;
//   }
//   .mode-toggle {
//     order: 2;
//   }
// }

.hidden {
  display: none;
}

.load-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 385px;
}

.pd-unset {
  padding-left: unset !important;
  padding-right: unset !important;
}
</style>
<style scoped>
@media only screen and (max-width: 600px) {
  .campaign-comparison :deep(.ant-card-body) {
    padding: 0 !important;
  }
}
</style>
