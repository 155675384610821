<template>
  <a-card
    class="trending-comparison-wrapper"
    :widget="true"
  >
    <div
      v-if="loading"
      class="loading-wrapper"
    >
      <a-skeleton
        :loading="loading"
        active
      />
    </div>
    <TrendingChart
      v-else-if="!loading && !noData"
      class="chart"
      :chart-type="chartType"
      :series="selectedSerie"
    ></TrendingChart>
    <NoResult
      v-else-if="
        (!loading && noData) ||
        (!loading && selectedSerie && selectedSerie.length === 0)
      "
      class="no-data-wrapper"
    />
    <!-- <SeeMoreDialog /> -->
  </a-card>
</template>

<script>
import { toRefs, ref, reactive, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import api from '@/services/api';
import helper from '@/services/helper';
import chartHelper from '@/services/chartHelper';
import TrendingChart from '@/components/Chart/TrendingChart';
import NoResult from '@/components/Error/NoResult.vue';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
export default {
  components: {
    TrendingChart,
    NoResult,
    // SeeMoreDialog,
  },
  props: {
    mode: {
      type: String,
      default: 'engagement',
    },
    filterResult: Object,
  },
  setup(props) {
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const categoryNameMap = computed(
      () => store.getters['config/defaultCategoryVisibleMap']
    );
    const campaignLevel = computed(
      () => store.state.account.campaignMenuData.categoryLevel
    );
    const chartType = ref('line');
    const seriesData = reactive({
      engagement: [],
      mentioned: [],
      view: [],
    });
    const selectedSerie = ref([]);
    let loading = ref(false);
    const noData = ref(false);

    const { mode, filterResult } = toRefs(props);
    const brand = computed(() => store.state.account.brand);
    const setMode = computed(() => {
      if (mode.value === 'mention') {
        return 'mentioned';
      } else {
        return mode.value;
      }
    });
    const currentGraphCriteria = ref({});
    const drilldownFn = (pointOption, payload = { title: 'data' }) => {
      const { graphTime } = currentGraphCriteria.value;
      const { x } = pointOption;
      const { time, title } = helper.getTimeOpt(x, graphTime);
      let f = {
        payload: {
          title: `${payload.title} ${title}`,
        },
        criteria: {
          ...filterResult.value,
          time,
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };

      if (campaignLevel.value > 0) {
        const category = payload.criteria.subCategory;
        for (let i in f.criteria['subCategory']) {
          if (f.criteria['subCategory'][i].level == campaignLevel.value) {
            f.criteria['subCategory'][i] = category[0];
          }
        }
      } else {
        const category = payload.criteria.category;
        f.criteria['category'] = category;
        f.criteria['subCategory'].splice(
          f.criteria['subCategory'].findIndex((x) => x.level === 0),
          1
        );
      }
      store.dispatch('message/showMessageModal', f);
    };

    const init = async (filter) => {
      loading.value = true;
      if (filter) {
        if (campaignLevel.value === 0) {
          filter['subCategory'].splice(
            filter['subCategory'].findIndex((x) => x.level === 0),
            1
          );
        }
        const { category, subCategory } = filter;
        let c = category;
        if (c.length === 0) {
          c = categoryNameMap.value[campaignLevel.value];
        }
        const { graphCriteria, granularity } = chartHelper.modifyGranularity(
          {},
          filter
        );
        currentGraphCriteria.value = graphCriteria;
        const filterCriteria = {
          ...filter,
        };

        const result = await api
          .getCampaignHistoryGraph(
            filterCriteria,
            brand.value,
            campaignLevel.value
          )
          .catch(() => {
            console.error('History api call error', result);
          });
        if (
          result.message &&
          result.message.graphData &&
          Object.keys(result.message.graphData).length !== 0
        ) {
          let c = categoryNameMap.value[campaignLevel.value];
          const graphResult = helper.prepareCategoryChartECV(
            result.message.graphData,
            new Set(subCategory.length !== 0 ? subCategory[0].category : c),
            drilldownFn,
            campaignLevel.value,
            c,
            granularity
          );

          Object.assign(seriesData, graphResult);
          selectedSerie.value = seriesData[setMode.value].sort((a, b) => {
            if (a.name === 'Others') {
              return 1;
            } else if (b.name === 'Others') {
              return -1;
            } else {
              return 0;
            }
          });
          // check no data
          checkSelectedSerieData();
        } else {
          noData.value = true;
        }
      }
      loading.value = false;
    };

    const checkSelectedSerieData = () => {
      if (selectedSerie.value && selectedSerie.value.length > 0) {
        noData.value = false;
      } else {
        noData.value = true;
      }
    };

    watch(
      () => filterResult.value,
      () => {
        init(filterResult.value);
      }
    );

    watch(
      () => mode.value,
      () => {
        init(filterResult.value);
      }
    );

    onMounted(() => {
      init(filterResult.value);
    });

    return {
      chartType,
      selectedSerie,
      loading,
      noData,
      isMobileScreen,
    };
  },
};
</script>

<style lang="scss">
@import '../../../config/theme/colors.json';
.trending-comparison-wrapper {
  .mode-dropdown-mobile {
    width: 104px;
    height: 100px;
  }
  .button-primary-light {
    /* TODO Refactor to outline btn */
    background: #2c99ff15;
    border-width: 0px;
    color: $primary-color;
    &:focus {
      background: #2c99ff15;
      border-width: 0px;
      color: $primary-color;
    }
  }

  .loading-wrapper,
  .no-data-wrapper {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
  }

  .ant-dropdown {
    .ant-dropdown-menu {
      min-width: 104px !important;
      height: 100px;
    }
  }

  .more-menu {
    color: #9299b8;
  }

  .mode-mobile {
    width: 104px;
    height: 100px;
    box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.2);
    border-radius: 6px;
  }
}
@media only screen and (max-width: 600px) {
  .trending-comparison-wrapper {
    padding: 0 20px;
  }
}
</style>
